import { Box } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { darken, lighten, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import clsx from "clsx";
import React, { forwardRef, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Override } from "../../types";

const useStyles = makeStyles(
  (theme) => ({
    button: {
      minWidth: 24,

      "&$success": {
        borderColor: darken(theme.palette.success.main, 0.25),
      },
      "&$error": {
        borderColor: darken(theme.palette.error.main, 0.25),
      },

      transition: theme.transitions.create(["color", "background-color", "opacity"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
      }),
    },
    spinner: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "transparent",

      "$success &": {
        color: lighten(theme.palette.success.main, 0.75),
      },
      "$error &": {
        color: lighten(theme.palette.error.main, 0.65),
      },
    },
    primarySpinner: {
      color: theme.colors.white,
    },
    success: {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    error: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    checkIcon: {
      height: 18,
      marginTop: -9,
      marginLeft: -9,
      width: 18,
    },
    hidden: {
      opacity: 0,
    },
  }),
  {
    classNamePrefix: "AsyncButton",
  }
);

export type AsyncButtonProps = Override<
  ButtonProps,
  {
    resetDelay?: number;
    successColor?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<unknown> | void;
    onSuccess?: (event: React.MouseEvent<HTMLButtonElement>, value: unknown) => void;
    onError?: (event: React.MouseEvent<HTMLButtonElement>, error: Error) => void;
    onReset?: () => void;
  }
>;

export const AsyncButton = forwardRef<HTMLButtonElement, PropsWithChildren<AsyncButtonProps>>(
  (
    { className, resetDelay = 3000, startIcon, children, successColor, onClick, onSuccess, onError, onReset, ...rest },
    ref
  ) => {
    const classes = useStyles();

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean | null>(null);

    const timer = React.useRef<NodeJS.Timeout>();

    useEffect(() => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      if (success !== null) {
        timer.current = setTimeout(() => {
          setSuccess(null);

          if (onReset) onReset();
        }, resetDelay);
      }

      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = undefined;
        }
      };
    }, [resetDelay, success, onReset]);

    const handleClick = useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (loading) return;
        if (!onClick) return;
        setLoading(true);

        try {
          const res = await onClick(e);
          setSuccess(true);
          onSuccess?.(e, res);
        } catch (err) {
          setSuccess(false);
          onError?.(e, err);
        }

        setLoading(false);
      },
      [loading, onClick, onError, onSuccess]
    );

    return (
      <Button
        ref={ref}
        className={clsx(classes.button, className, {
          [classes.success]: true === success,
          [classes.error]: false === success,
        })}
        startIcon={!loading && !success && startIcon}
        disabled={loading}
        onClick={handleClick}
        {...rest}
      >
        {/* loading */}
        {!!loading && (
          <CircularProgress
            size={24}
            className={clsx(classes.spinner, {
              [classes.primarySpinner]: rest.color === "primary" && rest.variant === "contained",
            })}
          />
        )}

        {/* default */}
        {!loading && null === success ? (
          // https://github.com/reclaim-ai/reclaim-worklifecalendar/pull/1502
          <span>{children}</span>
        ) : (
          <Box className={classes.hidden}>{children}</Box>
        )}
        {/* success */}
        {!loading && false === success && <ErrorIcon height={24} className={classes.spinner} />}
        {/* error */}
        {!loading && !!success && (
          <CheckIcon
            className={clsx(classes.spinner, classes.checkIcon)}
            style={{ color: successColor || undefined }}
          />
        )}
      </Button>
    );
  }
);
